.nav__container {
	@media (max-width: $xsmall-width) {
		padding: 0;
	}
	.footer-navigation, .header-navigation {
	 	@media (max-width: $xsmall-width) {
	 		margin-top: 40px;
	 	}
		&--primary{
			ul {
				font-size: 12px;
    			text-transform: uppercase;
				li {
					padding: 0;
					position: relative;
					text-align: left;
					vertical-align: top;
					width: 98px;
					@extend %transition-base;
					@media (min-width: $small-width) {
						display: inline-block;
					}
					@media (max-width: $xsmall-width) {
						text-align: center;
    					margin: auto;
    					border-bottom: 1px solid $gray-light;
					    width: 100%;
					    padding: 10px 0;
    				}
					@media (min-width: $large-width) {
						width: 159px;
					}
					&:hover {
						@extend %transition-base; 
						color: $color-primary;
					}
					a {
						color: $color-secondary;
						text-decoration: none;
					}
					&:last-of-type {
						border-bottom: 0;
					}
				}
				.sub-menu {
					margin-top: 10px;
					text-transform: initial;
					li:last-of-type {
						border: 0;
					}
				}
			}
		}
	}
	&.is-open{
		opacity: 1;
	}
	.header-navigation {
		@media (max-width: $xsmall-width) {
			display: none;
			margin-top: 10px;
		}
		@media (min-width: $small-width) {
			margin-top: 30px;
		}
		> ul {
			text-align: right;
			@media (max-width: $xsmall-width) {
				background-color: white;
			    z-index: 10;
			    position: relative;
			}
			> li {
				@media (max-width: $xsmall-width) {
					border-bottom: 0;
					border-top: 1px solid $gray-light;
				}
				@media (min-width: $small-width) {
					width: 95px;
				}
				@media (min-width: $large-width) {
					width: 125px;
				}
				&.current-menu-item, &.current-menu-parent {
					&:after {
						@media (min-width: $small-width) {
							content: "";
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 30px 30px 0;
							border-color: $white transparent transparent transparent;
							left: 0;
							position: absolute;
							top: 20px;
							z-index: 1; 
						}
					}
					a {
						color: $color-primary;
					}	
				}
				a:hover {
					color: $color-primary;
				}
			}
			.sub-menu {
				@media (min-width: $small-width) {
					background-color: white;
					box-shadow: 2px 2px 10px rgba(0,0,0,.5);
					display: none;
				    position: absolute;
				    z-index: 2;
				  }
			    li {
			    	padding: 10px;
			    	@media (min-width: $small-width) {
			    		width: 205px;
			    	}
			    	&:not(:last-of-type) {
			    		border-bottom: 0;
			    	}
			    	&:hover{
			    		background-color: $color-primary;
			    		cursor: pointer;
			    		a {
			    			color: white;
			    		}
			    	}
			    }
			}
		}
	}
}
