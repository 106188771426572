@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url(/dist/fonts/roboto-v20-latin-regular.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: bold;
	font-weight: 700;
	src: url(/dist/fonts/roboto-v20-latin-700.woff) format('woff');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url(/dist/fonts/Open_Sans-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	src: url(/dist/fonts/Open_Sans-normal-800.woff) format('woff');
}