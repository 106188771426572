.home {
	.banner {
		background-image: url('/dist/images/bg-service-aide-entreprise.jpg');
		background-position: 95%;
		background-repeat: no-repeat;
		height: 265px;
		background-size: cover;
		@media (min-width: $large-width) {
			height: 400px;
			background-position: right;
		}
		.container {
			height: 100%;
			@media (max-width: $xsmall-width) {
				background-color: rgba(0,0,0,0.4);
			}
			h1 {
				color: white;
				font-size: 45px;
				line-height: 45px;
				margin-left: 40px;
				margin-top: 0;
				text-shadow: 2px 2px 6px rgba(0,0,0,.5);
				@media (max-width: $xsmall-width) {
					margin-left: 0;
					text-align: center;
				}
				@media (min-width: $large-width) {
					font-size: 60px;
					line-height: 60px;
					text-shadow: 2px 2px 10px rgba(0,0,0,.75);
				}
			}
		}
	}
}
.banner-image {
	background-position: center;
	height: 200px;
	position: relative;
	h2 {
		color: white;
		left: 0;
    	margin-right: auto;
    	margin-left: auto;
    	position: absolute;
    	font-size: 45px;
    	right: 0;
    	text-align: center;
    	text-shadow: 2px 2px 10px rgba(0,0,0,.75);
    	@media (max-width: $xsmall-width) {
    		font-size: 30px;
    		margin-top: 60px;
    	}
	}
}