.menu__trigger {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 26px;
    height: 14px;
    font-size: 12px;
    @media only screen and (min-width: $small-width) {
            display: none;
        }
    
    &:before {
        position: absolute;
        top: $spacing-medium-vertical;
        left: 50%;
        margin-left: -13px;
        content: 'Menu';
        text-transform: uppercase;
        font-size: em(13px);
        color: $color-primary;
    }

    .line {
        display: block;
        position: absolute;
        left: 0;
        width: 26px;
        height: 2px;
        background: $color-primary;

        @extend %transition-base;

        &.line-1 {
            top: 0;
        }

        &.line-2 {
            top: 50%;
        }

        &.line-3 {
            top: 100%;			
        }
    }
    
    &.is-open {
        .line-1 {
            transform: translateY(7px) translateX(0) rotate(45deg);
        }
        
        .line-2 {
            opacity: 0;
        }
        
        .line-3 {
            transform: translateY(-7px) translateX(0) rotate(-45deg);
        }
    }
}