// ==========================================================================
// Helpers
// ==========================================================================

// Clearfix

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clearfix {
	@extend %clearfix;
}


// Center with bootstrap

%col-center {
	float: none;
	margin: 0 auto;
}

.col-center {
	@extend %col-center;
}


// Screen reader text

.srt {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Images

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

// Position
	.inlined { display: inline-block; }
    .blocked { display: block; }


// Width

	.quarter { width: 25%;}
	.fifty   { width: 50%;}
	.seven   { width: 75%;}
	.full    { width: 100%!important;}


// Align
.centered {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}


// Modifiers
.text-uppercase {
	text-transform: uppercase;
}

.text-bold {
	font-weight: bold;
}

.white {
	color: $white;
}
.color--secondary{
    color: $color-secondary;
}

.color--primary{
    color: $color-primary;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.overflow-hide {
  overflow: hidden;
}

.overflow-show {
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

// For pseudo-elements

%pseudos {
  display: block;
  content: ''; 
  position: absolute;
}


// Transition
%transition-base {
    transition: all 240ms ease;
}

%transition-slow {
    transition: all 480ms ease;
}

%transition-xslow {
    transition: all 620ms ease; 
}

%transition-xxslow {
    transition: all 840ms ease; 
} 


// Remove default style of list

%unlist {
	list-style-type: none;
}

.vertical-align {
	top: 50%;
	position: relative;
	transform: translateY(-50%);
}


.vcenter {
	display: table;
    width: 100%;
    height: 100%;
}

.vcenter-element {
	display:table-cell;
	vertical-align:middle;
    width: 100%;
    height: 100%;

    &--padding {
    	padding: 10px 0;
    }
}


// Table display
%table {
	display: table;
}

%table-cell {
	display: table-cell;
}

// Spacing
// Exemple: <p class="spacing--xxlarge spacing--0bottom">

.spacing {

	// Modifiers

	&--base {
		padding-top: $spacing-base-vertical;
		padding-bottom: $spacing-base-vertical;
	}

	&--medium {
		padding-top: $spacing-medium-vertical;
		padding-bottom: $spacing-medium-vertical;
	}

	&--large {
		padding-top: $spacing-large-vertical;
		padding-bottom: $spacing-large-vertical;
	}

	&--xlarge {
		padding-top: $spacing-xlarge-vertical;
		padding-bottom: $spacing-xlarge-vertical;
	}

	&--xxlarge {
		padding-top: $spacing-xxlarge-vertical;
		padding-bottom: $spacing-xxlarge-vertical;
	}

	&--jumbo {
		padding-top: $spacing-jumbo-vertical;
		padding-bottom:$spacing-jumbo-vertical;
	}

	&--0top {
		padding-top: 0;
	}

	&--0bottom {
		padding-bottom: 0;
	}
	&--0left {
		padding-left: 0;
	}
	&--0right {
		padding-right: 0;
	}
	
}

// Spacing
.m0  { margin:        0 }
.m0--imp  { margin:   0!important;}
.mt0 { margin-top:    0!important;}
.mr0 { margin-right:  0!important;}
.mb0 { margin-bottom: 0!important;}
.ml0 { margin-left:   0!important;}

.m1  { margin:        $spacing-base; }
.mt1 { margin-top:    $spacing-base; }
.mr1 { margin-right:  $spacing-base; }
.mb1 { margin-bottom: $spacing-base; }
.ml1 { margin-left:   $spacing-base; }

.m2  { margin:        $spacing-medium; }
.mt2 { margin-top:    $spacing-medium; }
.mr2 { margin-right:  $spacing-medium; }
.mb2 { margin-bottom: $spacing-medium; }
.ml2 { margin-left:   $spacing-medium; }

.m3  { margin:        $spacing-large; }
.mt3 { margin-top:    $spacing-large; }
.mr3 { margin-right:  $spacing-large; }
.mb3 { margin-bottom: $spacing-large; }
.ml3 { margin-left:   $spacing-large; }

.m4  { margin:        $spacing-xlarge; }
.mt4 { margin-top:    $spacing-xlarge; }
.mr4 { margin-right:  $spacing-xlarge; }
.mb4 { margin-bottom: $spacing-xlarge; }
.ml4 { margin-left:   $spacing-xlarge; }


.m5  { margin:        $spacing-xxlarge; }
.mt5 { margin-top:    $spacing-xxlarge; }
.mr5 { margin-right:  $spacing-xxlarge; }
.mb5 { margin-bottom: $spacing-xxlarge; }
.ml5 { margin-left:   $spacing-xxlarge; }

.p0  { padding: 0!important;}

.pt0 { padding-top:0; }
.pt2 { padding-top: 20px; }
.pb0 { padding-bottom:0!important; }
.pl0 { padding-left:0!important; }

.p1  { padding:       $spacing-base; }
.py1 { padding-top:   $spacing-base; padding-bottom: $spacing-base; }
.px1 { padding-left:  $spacing-base; padding-right:  $spacing-base; }

.p2  { padding:       $spacing-medium; }
.py2 { padding-top:   $spacing-medium!important; padding-bottom: $spacing-medium; }
.px2 { padding-left:  $spacing-medium!important; padding-right:  $spacing-medium!important; }

.p3  { padding:       $spacing-large; }
.py3 { padding-top:   $spacing-large; padding-bottom: $spacing-large; }
.px3 { padding-left:  $spacing-large; padding-right:  $spacing-large; }

.p4  { padding:       $spacing-xlarge; }
.py4 { padding-top:   $spacing-xlarge; padding-bottom: $spacing-xlarge; }
.px4 { padding-left:  $spacing-xlarge; padding-right:  $spacing-xlarge; }

.bordered {
	border: 1px solid $gray-base;
	padding-top: 15px;
	padding-bottom: 15px;
}
.row-centered {
    text-align:center;
}
.col-centered {
    display: inline-block;
    float: none;
}

.fixed-element {
    position:fixed;
    top:0;
    z-index:200;
}