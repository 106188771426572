main  {
	min-height: 500px;
	.bloc--services {
		img {
			max-height: 160px;
		}
	}

	h1, h2 {
		color: $color-primary;
		font-size: 30px;
		margin-top: 60px;
		@media (max-width: $xsmall-width) {
			text-align: center;
			margin-top: 30px;
		}
	}
	li {
		color: $color-primary;
    	padding-bottom: 20px;
    	span {
    		color: #5c5c5c;
    	}
	}

	p {
		color: #5c5c5c;
		margin-top: 40px;
		@media (max-width: $xsmall-width) {
			margin-top: 20px;
		}
	}

	ul {
		color: #5c5c5c;
		margin-top: 40px;
		margin-left: 40px;
		@media (max-width: $xsmall-width) {
			margin-top: 20px;
			margin-left: 20px;
			margin-bottom: 20px;
		}
	}
}