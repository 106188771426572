.copyright {
	background-color: $color-secondary;
	color: white;
	padding: 10px 0;
	text-align: center;
	@media (min-width: $small-width) {
		text-align: left;
	}
}
.credit {
	text-align: center;
	@media (min-width: $small-width) {
		text-align: right;
	}
}
footer {
	background-color: #f3f3f3;

	padding: 20px 0;
	@media (min-width: $small-width) {
		margin-top: 90px;
	}

	.bloc__logo {
		@media (max-width: $xsmall-width) {
			text-align: center;
		}
		img {
			height: 35px;
			@media (min-width: $large-width) {
				height: 45px;
			}
		}
		i {
			color: $color-primary;
			margin-right: 5px;
			@media (max-width: $xsmall-width) {
				display: none;
			}
		}
		.phone {
			color: $color-primary;
			font-size: 16px;
			font-weight: bold;
			@media (min-width: $large-width) {
				font-size: 18px;
			}
		}
	}

	
}